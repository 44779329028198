@font-face {
  font-family: "Social";
  font-weight: normal;
  font-style: normal;
  font-display: block;
  src: url("/theme/assets/fonts/social_media.woff2") format("woff2"), url("/theme/assets/fonts/social_media.woff") format("woff");
}
.icon-social {
  font-style: normal;
  font-family: "Social";
}
.icon-social.icon-facebook:before {
  content: "\f09a";
}
.icon-social.icon-twitter:before {
  content: "\f099";
}
.icon-social.icon-envelope:before {
  content: "\f0e0";
}
.icon-social.icon-linkedin:before {
  content: "\f0e1";
}
.icon-social.icon-youtube-play:before {
  content: "\f16a";
}
.icon-social.icon-instagram:before {
  content: "\f16d";
}
.icon-social.icon-google:before {
  content: "\f1a0";
}
.icon-social.icon-behance:before {
  content: "\f1b4";
}
.icon-social.icon-pinterest-p:before {
  content: "\f231";
}
.icon-social.icon-whatsapp:before {
  content: "\f232";
}
.icon-social.icon-vimeo:before {
  content: "\f27d";
}

/*  Colors */
/* Fonts */
/*  Sizes */
/*  Padding */
/*  Padding sections */
/*  Padding columns */
/* Breakpoints */
/* Navigation */
/* Animation speed */
/* Swiper */
body,
button,
input,
select,
textarea {
  -webkit-font-smoothing: antialiased;
  font-family: "Poppins", sans-serif;
}

body {
  font-weight: 300;
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
  font-size: 1.4rem;
  line-height: 2.2rem;
}
@media (min-width: 768px) {
  body {
    font-size: 1.4rem;
    line-height: 2.5rem;
  }
}

b {
  font-weight: 700;
}

a {
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
  text-decoration: none;
}
a:hover {
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
}

ul {
  list-style: none;
}
ul,
ul li {
  padding: 0;
}

h1 {
  font-size: 3.5rem;
  line-height: 4.5rem;
  font-weight: 700;
  margin-bottom: 2rem;
  position: relative;
  display: block;
}
h1:not(.noline) {
  padding-bottom: 1.5rem;
}
h1:not(.noline)::after, h1:not(.noline)::before {
  content: "";
  position: absolute;
  bottom: 0;
  background-color: rgb(209, 39, 47);
  background-color: rgba(var(--color-secondary), 1);
  height: 0.4rem;
}
h1:not(.noline)::before {
  left: 0;
  width: 2rem;
}
h1:not(.noline)::after {
  left: 3.5rem;
  width: 6.5rem;
}
h1:not(.noline).text-center::after, h1:not(.noline).text-center::before {
  left: 50%;
  transform: translateX(-50%);
}
h1:not(.noline).text-center::after {
  transform: translateX(calc(-50% - 1.75rem));
}
h1:not(.noline).text-center::before {
  transform: translateX(calc(-50% + 3.75rem));
}
h1:not(.noline).text-right::before {
  right: 0;
  width: 2rem;
}
h1:not(.noline).text-right::after {
  right: 3.5rem;
  width: 6.5rem;
}
h1:not(.noline).line-reversed::before {
  left: 8rem;
}
h1:not(.noline).line-reversed::after {
  left: 0;
}
h1:not(.noline).line-reversed.text-center::after, h1:not(.noline).line-reversed.text-center::before {
  left: 50%;
  transform: translateX(-50%);
}
h1:not(.noline).line-reversed.text-center::after {
  transform: translateX(calc(-50% + 3.5rem));
}
h1:not(.noline).line-reversed.text-center::before {
  transform: translateX(calc(-50% - 3.5rem));
}
h1:not(.noline).line-reversed.text-right::before {
  right: 8rem;
  width: 2rem;
}
h1:not(.noline).line-reversed.text-right::after {
  right: 0;
  width: 6.5rem;
}
h1.line-top {
  padding-top: 1.5rem;
}
h1.line-top::before {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgb(209, 39, 47);
  background-color: rgba(var(--color-secondary), 1);
  height: 0.4rem;
}
h1.line-top::before {
  left: 0;
  width: 6rem !important;
}
h1.line-top.text-center::before {
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  h1 {
    font-size: 4.6rem;
    line-height: 6rem;
  }
}

.paragraph h2 {
  font-size: 2.4rem;
  line-height: 3.5rem;
  font-weight: 700;
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
  margin-bottom: 2rem;
  position: relative;
  display: block;
}
.paragraph h2:not(.noline) {
  padding-bottom: 1.5rem;
}
.paragraph h2:not(.noline)::after, .paragraph h2:not(.noline)::before {
  content: "";
  position: absolute;
  bottom: 0;
  background-color: rgb(209, 39, 47);
  background-color: rgba(var(--color-secondary), 1);
  height: 0.4rem;
}
.paragraph h2:not(.noline)::before {
  left: 0;
  width: 2rem;
}
.paragraph h2:not(.noline)::after {
  left: 3.5rem;
  width: 6.5rem;
}
.paragraph h2:not(.noline).text-center::after, .paragraph h2:not(.noline).text-center::before {
  left: 50%;
  transform: translateX(-50%);
}
.paragraph h2:not(.noline).text-center::after {
  transform: translateX(calc(-50% - 1.75rem));
}
.paragraph h2:not(.noline).text-center::before {
  transform: translateX(calc(-50% + 3.75rem));
}
.paragraph h2:not(.noline).text-right::before {
  right: 0;
  width: 2rem;
}
.paragraph h2:not(.noline).text-right::after {
  right: 3.5rem;
  width: 6.5rem;
}
.paragraph h2:not(.noline).line-reversed::before {
  left: 8rem;
}
.paragraph h2:not(.noline).line-reversed::after {
  left: 0;
}
.paragraph h2:not(.noline).line-reversed.text-center::after, .paragraph h2:not(.noline).line-reversed.text-center::before {
  left: 50%;
  transform: translateX(-50%);
}
.paragraph h2:not(.noline).line-reversed.text-center::after {
  transform: translateX(calc(-50% + 3.5rem));
}
.paragraph h2:not(.noline).line-reversed.text-center::before {
  transform: translateX(calc(-50% - 3.5rem));
}
.paragraph h2:not(.noline).line-reversed.text-right::before {
  right: 8rem;
  width: 2rem;
}
.paragraph h2:not(.noline).line-reversed.text-right::after {
  right: 0;
  width: 6.5rem;
}
.paragraph h2.line-top {
  padding-top: 1.5rem;
}
.paragraph h2.line-top::before {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgb(209, 39, 47);
  background-color: rgba(var(--color-secondary), 1);
  height: 0.4rem;
}
.paragraph h2.line-top::before {
  left: 0;
  width: 6rem !important;
}
.paragraph h2.line-top.text-center::before {
  transform: translateX(-50%);
}
@media (min-width: 768px) {
  .paragraph h2 {
    font-size: 3.6rem;
    line-height: 5rem;
  }
}

.paragraph--line-top h2 {
  padding-top: 1.5rem;
}
.paragraph--line-top h2::before {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgb(209, 39, 47);
  background-color: rgba(var(--color-secondary), 1);
  height: 0.4rem;
}
.paragraph--line-top h2::before {
  left: 0;
  width: 6rem !important;
}
.paragraph--line-top h2.text-center::before {
  transform: translateX(-50%);
}
.paragraph--line-top h2::after {
  display: none;
}
.paragraph--line-top h2::before {
  bottom: unset;
}

/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
}

.swiper-container-vertical > .swiper-wrapper {
  flex-direction: column;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  transition-property: transform;
  box-sizing: content-box;
}

.swiper-container-android .swiper-slide,
.swiper-wrapper {
  transform: translate3d(0px, 0, 0);
}

.swiper-container-multirow > .swiper-wrapper {
  flex-wrap: wrap;
}

.swiper-container-multirow-column > .swiper-wrapper {
  flex-wrap: wrap;
  flex-direction: column;
}

.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: ease-out;
  margin: 0 auto;
}

.swiper-slide {
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  transition-property: transform;
}

.swiper-slide-invisible-blank {
  visibility: hidden;
}

/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  align-items: flex-start;
  transition-property: transform, height;
}

/* 3D Effects */
.swiper-container-3d {
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}

/* CSS Mode */
.swiper-container-css-mode > .swiper-wrapper {
  overflow: auto;
  scrollbar-width: none;
  /* For Firefox */
  -ms-overflow-style: none;
  /* For Internet Explorer and Edge */
}
.swiper-container-css-mode > .swiper-wrapper::-webkit-scrollbar {
  display: none;
}
.swiper-container-css-mode > .swiper-wrapper > .swiper-slide {
  scroll-snap-align: start start;
}

.swiper-container-horizontal.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: x mandatory;
}

.swiper-container-vertical.swiper-container-css-mode > .swiper-wrapper {
  scroll-snap-type: y mandatory;
}

.swiper-container-cube {
  overflow: visible;
}
.swiper-container-cube .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}

.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}

.swiper-container-flip {
  overflow: visible;
}
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  backface-visibility: hidden;
}

/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  transform-origin: 50%;
  animation: swiper-preloader-spin 1s infinite linear;
  box-sizing: border-box;
  border: 4px solid black;
  border-radius: 50%;
  border-top-color: transparent;
}

@keyframes swiper-preloader-spin {
  100% {
    transform: rotate(360deg);
  }
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  width: 27px;
  height: 44px;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev:after,
.swiper-button-next:after {
  font-family: swiper-icons;
  font-size: 44px;
  text-transform: none !important;
  letter-spacing: 0;
  text-transform: none;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  left: 10px;
  right: auto;
}
.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "prev";
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  right: 10px;
  left: auto;
}
.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "next";
}

.swiper-button-lock {
  display: none;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}

/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}

/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}

.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: #000;
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  box-shadow: none;
  appearance: none;
}

.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
  background: white;
}

.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  transform: translateY(-50%);
  width: 8px;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  transition: 200ms transform, 200ms top;
}

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms left;
}
.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  transition: 200ms transform, 200ms right;
}

/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: white;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transform: scale(0);
  transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progressbar, .swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progressbar, .swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}

.swiper-pagination-primaryy {
  --swiper-pagination-color: rgb(25, 36, 59);
}

.swiper-pagination-primary {
  --swiper-pagination-color: rgba(var(--color-primary), 1);
}

.swiper-pagination-secondaryy {
  --swiper-pagination-color: rgb(209, 39, 47);
}

.swiper-pagination-secondary {
  --swiper-pagination-color: rgba(var(--color-secondary), 1);
}

.swiper-pagination-tertiaryy {
  --swiper-pagination-color: rgb(39, 223, 112);
}

.swiper-pagination-tertiary {
  --swiper-pagination-color: rgba(var(--color-tertiary), 1);
}

.swiper-pagination-white {
  --swiper-pagination-color: white;
}

.swiper-pagination-black {
  --swiper-pagination-color: black;
}

.swiper-pagination-lightgreyy {
  --swiper-pagination-color: rgb(250, 250, 250);
}

.swiper-pagination-lightgrey {
  --swiper-pagination-color: rgba(var(--color-lightgrey), 1);
}

.swiper-pagination-grey {
  --swiper-pagination-color: rgba(0, 0, 0, 0.16);
}

.swiper-pagination-rangegrey {
  --swiper-pagination-color: rgb(211, 211, 211);
}

.swiper-pagination-lock {
  display: none;
}

/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}

.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}

.swiper-scrollbar-cursor-drag {
  cursor: move;
}

.swiper-scrollbar-lock {
  display: none;
}

.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container > img, .swiper-zoom-container > svg, .swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.swiper-slide-zoomed {
  cursor: move;
}

.plate--column {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.plate--container,
.plate--container-fluid {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.plate--row {
  margin-right: -2.5rem;
  margin-left: -2.5rem;
}

@media (min-width: 768px) {
  .plate--column {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .plate--container,
  .plate--container-fluid {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
  .plate--row {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
}
.menu_item__submenu__wrapper .plate--column,
.section--small .plate--column {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.menu_item__submenu__wrapper .plate--row,
.section--small .plate--row {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
@media (min-width: 768px) {
  .menu_item__submenu__wrapper .plate--column,
  .section--small .plate--column {
    padding-right: 0.8rem;
    padding-left: 0.8rem;
  }
  .menu_item__submenu__wrapper .plate--row,
  .section--small .plate--row {
    margin-right: -0.8rem;
    margin-left: -0.8rem;
  }
}

picture {
  position: relative;
}
picture source {
  position: absolute;
  top: 0;
}

* {
  box-sizing: border-box;
  margin: 0;
}

main {
  z-index: 100;
  position: relative;
}

.radius,
.radius img {
  border-radius: 0.8rem;
}

.updatecss {
  position: fixed;
  top: 10rem;
  left: 2rem;
  padding: 2rem;
  border: none;
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  z-index: 99999999;
}
.updatecss:hover {
  background-color: rgb(25, 36, 59);
  background-color: rgba(var(--color-primary), 1);
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

picture,
picture img {
  display: block;
}
picture source {
  display: block;
  width: 100%;
  height: 100%;
}

.body__inner {
  position: relative;
  z-index: 1;
}

head {
  position: relative;
  z-index: 2;
}

.accordion__item__header {
  padding: 1rem;
  cursor: pointer;
  border-top: 1px solid rgb(25, 36, 59);
  border-top: 1px solid rgba(var(--color-primary), 1);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion__item__header svg {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin-left: 2rem;
  transform: rotate(90deg);
}
.accordion__item__header svg path {
  fill: rgb(25, 36, 59);
  fill: rgba(var(--color-primary), 1);
}
.accordion__item__header--active svg {
  transform: rotate(270deg);
}
.accordion__item__header:hover {
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
}
.accordion__item__header:hover svg path {
  fill: rgb(209, 39, 47);
  fill: rgba(var(--color-secondary), 1);
}
.accordion__item:last-of-type {
  border-bottom: 1px solid rgb(25, 36, 59);
  border-bottom: 1px solid rgba(var(--color-primary), 1);
}
.accordion__item__body {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
.accordion__item__body .paragraph {
  border-top: 1px solid rgb(25, 36, 59);
  border-top: 1px solid rgba(var(--color-primary), 1);
  padding: 1rem 1rem 2rem;
}
.accordion__item__body:not(.accordion__item__body--active) {
  max-height: 0 !important;
}
.accordion__item,
.accordion__item h3 {
  font-weight: 300;
  font-size: 1.4rem;
}

.block {
  z-index: 1;
  position: relative;
  background-color: white;
  border-radius: 0.8rem;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.1607843137);
}
.block,
.block h2 {
  color: rgba(var(--color-primary), 1) !important;
}
.block--small {
  padding: 5rem 3rem;
}
@media (max-width: 767.98px) {
  .block--small {
    margin-bottom: 3rem;
  }
}
.block--large {
  padding: 5rem 3rem;
}
@media (min-width: 576px) {
  .block--large {
    padding: 5rem 3rem 5rem 9rem;
  }
}
@media (min-width: 992px) {
  .block--large {
    padding: 10rem 18rem;
  }
}
@media (min-width: 768px) {
  .block--overlap--desktop--left {
    margin-left: calc(-8rem - 3.5rem);
    width: calc(100% + 8rem + 3.5rem);
  }
}

.card {
  position: relative;
  text-decoration: none;
  border-radius: 0.8rem;
  overflow: hidden;
  display: block;
}
.card__info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: center;
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 35%;
  display: flex;
  flex-direction: column;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.card__info__dropdown {
  cursor: pointer;
}
.card__info__dropdown .card__title {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  font-weight: 700;
}
.card__info__dropdown .card__title .hide,
.card__info__dropdown .card__title .show {
  margin-right: 2rem;
}
.card__info__dropdown .card__title svg {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 1rem;
  height: 1rem;
}
.card__info__dropdown .card__title svg path {
  fill: white;
}
.card__info__dropdown .card__title .hide {
  display: none;
}
.card__info__dropdown .card__title.active .show {
  display: none;
}
.card__info__dropdown .card__title.active .hide {
  display: block;
}
.card__info__dropdown:not(.active) svg {
  transform: rotate(-90deg);
}
.card__title {
  font-size: 1.4rem;
  line-height: 1.5rem;
  text-align: left;
  font-weight: 700;
}
.card__text {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-size: 1.4rem;
  flex: 0 0 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
@media (max-width: 767.98px) {
  .card__text {
    display: none;
  }
}
.card__text .link {
  color: white;
}
.card:hover .card__text {
  flex: 1 1 100%;
  margin-top: 1rem;
}
.card:hover .card__info--hover {
  height: calc(100% - 3.5rem);
}
.card__image {
  width: 100%;
}
.card__image img,
.card__image picture {
  width: 100%;
}
.card--empty {
  display: flex;
  align-items: center;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.1607843137);
  text-decoration: none;
  cursor: pointer;
}
.card--empty__text {
  position: absolute !important;
  left: 0;
  padding: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
}
@media (min-width: 768px) {
  .card--empty__text {
    padding: 3rem;
  }
}
.card--empty:hover svg {
  margin-left: 1rem;
}
.card--empty:hover .link {
  color: rgba(var(--color-secondary), 1);
}

.popup-wrapper {
  position: fixed;
  inset: 0;
  background-color: rgba(111, 112, 112, 0.45);
  z-index: 99999991;
  display: grid;
  justify-content: center;
  align-items: center;
}

.popup {
  background-color: white;
  border-radius: 0.8rem;
  min-height: 10ch;
  max-width: 90rem;
  box-shadow: 0px 30px 99px rgba(0, 0, 0, 0.1607843137);
  padding: 4rem;
  position: relative;
}
.popup h2 {
  font: normal normal bold 36px/55px Poppins;
}
.popup p {
  font: normal normal normal 16px/25px Poppins;
}
@media (max-width: 767.98px) {
  .popup {
    overflow-y: scroll;
    max-height: 100vh;
    padding-top: 10rem;
  }
}
@media (min-width: 992px) {
  .popup:after {
    z-index: 10;
    content: "";
    background: radial-gradient(ellipse at center, rgba(var(--color-primary), 1) 0%, rgba(var(--color-primary), 1) 30%, transparent 30%);
    background-repeat: repeat;
    background-position: center right;
    background-size: 1rem 1rem;
    position: absolute;
    left: 4rem;
    bottom: 0;
    width: 24rem;
    height: 9rem;
  }
}

.column-first {
  justify-content: flex-end;
}
@media (min-width: 768px) {
  .column-first {
    order: -1;
  }
}

.download-category-title {
  font-size: 2.6rem;
  font-weight: bold;
  margin: 2rem;
  align-self: center;
}
@media (min-width: 768px) {
  .download-category-title {
    font-size: 3.6rem;
    align-self: start;
  }
}

.download-categories {
  display: flex;
  flex-wrap: wrap;
}

.download-link {
  min-width: 25%;
  margin: 0.5rem;
  padding: 1rem;
  border: 3px solid rgba(var(--color-primary), 1);
  background-color: transparent;
  transition: all 0.3s ease;
  font-size: 1.6rem;
  font-weight: bold;
  color: rgba(var(--color-primary), 1);
  border-radius: 0.5rem;
  text-align: center;
}
.download-link:hover, .download-link.active {
  background-color: rgba(var(--color-primary), 1);
  color: white;
}

.download-tables {
  display: flex;
  flex-wrap: wrap;
}
.download-tables .download-table {
  width: 100%;
  margin-bottom: 6rem;
}
@media (min-width: 1200px) {
  .download-tables.tables-2 .download-table {
    width: auto;
    flex: 1;
  }
  .download-tables.tables-3 .download-table {
    width: auto;
    flex: 1;
  }
}

.download-table {
  font-size: 2.4rem;
  line-height: 1.4583333333;
  border-collapse: collapse;
}
.download-table th,
.download-table td {
  padding: 1rem;
}
.download-table tr:hover {
  background-color: rgb(250, 250, 250);
}

.name-cell {
  font-weight: bold;
  text-align: start;
}

.download-second-heading {
  border: 1px solid #ccc;
}
.download-second-heading th {
  border: 1px solid #ccc;
}
@media (max-width: 767.98px) {
  .download-second-heading {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
.download-second-heading th:first-of-type {
  text-align: start;
}

.download-second-heading {
  border: 1px solid #ccc;
}
.download-second-heading th {
  border: 1px solid #ccc;
}

tbody td {
  border: 1px solid #ccc;
}

.download-cell {
  text-align: center;
}

.download-row-link {
  color: rgba(var(--color-primary), 1);
}
.download-row-link.empty {
  opacity: 0.2;
}
.download-row-link svg {
  width: 3rem;
}

.visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@media (min-width: 1200px) {
  .set-2 .name-cell,
  .set-2 th:first-of-type,
  .set-3 .name-cell,
  .set-3 th:first-of-type {
    display: none;
  }
}

.filter__input__range {
  padding-bottom: 2rem;
  position: relative;
}
.filter__input__range__bar {
  height: 1rem;
  position: relative;
  background-color: lightgrey;
  margin: 0 1rem;
}
.filter__input__range__bar__max, .filter__input__range__bar__min {
  width: 1rem;
  height: 1rem;
  transform: translateY(-50%) translateX(-50%);
  border-radius: 50%;
  background-color: rgb(25, 36, 59);
  background-color: rgba(var(--color-primary), 1);
  z-index: 2;
}
.filter__input__range__bar__range {
  height: 1rem;
  background: lightblue;
  transform: translateY(-50%);
}
.filter__input__range__bar__max, .filter__input__range__bar__min, .filter__input__range__bar__range {
  position: absolute;
  top: 50%;
}
.filter__input__range__bar__min {
  left: 0;
}
.filter__input__range__bar__max {
  left: 100%;
}
.filter__input__range__selector {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  height: 1rem;
}
.filter__input__range__selector input {
  pointer-events: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.filter__input__range__selector--active {
  pointer-events: all !important;
}

.pagination {
  display: flex;
}
.pagination ul {
  display: flex;
}

.filter__input__taxonomy {
  margin-bottom: 2rem;
}
.filter__input__taxonomy__title {
  font-weight: 700;
}
.filter__search {
  margin-bottom: 2rem;
}
.filter__result {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.filter__result--loading {
  opacity: 0.6;
}
.filter__result__loader {
  width: 100%;
  max-height: 5rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.filter__result__loader__inner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.filter__result__loader svg {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  height: 100%;
  height: 5rem;
  animation-name: loader;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-fill-mode: linear;
  animation-timing-function: linear;
}
@keyframes loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.filter__result--full .filter__result__loader {
  opacity: 0;
  max-height: 0;
}
.filter__result--full .filter__result__loader svg {
  height: 0;
}

.header {
  position: relative;
  color: white;
}
.header .plate--row {
  width: calc(100% + 2 * 3.5rem);
}
.header .vimeo {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
@media (min-width: 1400px) {
  .header .search:hover input {
    cursor: pointer;
    background-color: white !important;
    color: rgba(var(--color-primary), 1);
  }
  .header .search:hover input, .header .search:hover input::placeholder,
  .header .search:hover input path {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .header .search:hover input::placeholder {
    color: rgba(var(--color-primary), 1);
  }
  .header .search:hover svg path {
    fill: rgba(var(--color-primary), 1) !important;
  }
}
.header__popup_video {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 1.5rem;
  display: flex;
  align-items: center;
  color: white;
}
.header__popup_video:hover {
  color: white;
}
@media (min-width: 768px) {
  .header__popup_video {
    bottom: 5rem;
  }
  .header__popup_video span {
    display: none;
  }
}
.header__popup_video span {
  font-size: 1.2rem;
}
.header__popup_video svg {
  width: 2rem;
  height: 2rem;
  overflow: visible;
}
@media (max-width: 767.98px) {
  .header__popup_video svg {
    margin-right: 1rem;
  }
}
@media (min-width: 768px) {
  .header__popup_video svg {
    width: 5rem;
    height: 5rem;
  }
}
.header__popup_video svg circle,
.header__popup_video svg path {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.header__popup_video:hover path {
  fill: rgb(25, 36, 59);
  fill: rgba(var(--color-primary), 1);
}
.header__popup_video:hover circle {
  fill: white;
}
.header__video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}
.header__video video {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
  max-height: 100vw;
  max-width: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.header .map {
  height: 43rem;
  width: 100%;
  z-index: 0;
  padding: 0 !important;
}
.header .map + .header__content {
  z-index: 1;
  pointer-events: none;
}
.header .map + .header__content h1 {
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
}
.header .image,
.header .vimeo--bg {
  height: 43rem;
  width: 100%;
}
.header .image iframe,
.header .vimeo--bg iframe {
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
}
@media (min-width: 768px) {
  .header .image,
  .header .vimeo--bg {
    height: 65rem;
  }
}
.header .image img,
.header .vimeo--bg img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.header--small .image,
.header--small .vimeo--bg {
  height: 37rem;
}
.header--small .map {
  height: 37rem;
  width: 100%;
  padding: 0 !important;
}
.header--small .header__content {
  height: 100%;
}
.header--small .plate--container {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.header--small .plate--container::after {
  z-index: 10;
  content: "";
  background: radial-gradient(ellipse at center, rgb(25, 36, 59) 0%, rgb(25, 36, 59) 30%, transparent 30%);
  background: radial-gradient(ellipse at center, rgba(var(--color-primary), 1) 0%, rgba(var(--color-primary), 1) 30%, transparent 30%);
  background-repeat: repeat;
  background-position: center right;
  position: absolute;
  right: 0;
  bottom: 0;
  transform: translateY(75%);
  background-size: 0.6rem 0.6rem;
  width: 23rem;
  height: 4.2rem;
}
@media (min-width: 768px) {
  .header--small .plate--container::after {
    background-size: 1rem 1rem;
    width: 33rem;
    height: 7rem;
    right: 3.5%;
  }
}
@media (min-width: 1400px) {
  .header--small .plate--container::after {
    right: 9%;
  }
}
.header--large {
  z-index: 10;
}
.header--large .vimeo--bg iframe {
  width: 100%;
}
.header:not(.header--large) .vimeo--bg iframe {
  height: 100%;
}
.header__content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
}

.img_text_dropdown {
  margin-bottom: 3rem;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .img_text_dropdown {
    flex-direction: row;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
.img_text_dropdown .card {
  cursor: pointer;
}
.img_text_dropdown__image {
  flex: 1 1 30%;
}
@media (max-width: 767.98px) {
  .img_text_dropdown__image {
    order: 2;
  }
}
.img_text_dropdown__image .card {
  width: 100%;
}
.img_text_dropdown__text {
  flex: 1 1 70%;
  padding: 3rem 0;
}
@media (max-width: 767.98px) {
  .img_text_dropdown__text {
    order: 1;
  }
}
@media (min-width: 768px) {
  .img_text_dropdown__text {
    padding: 0 7rem;
  }
}
@media (min-width: 1200px) {
  .img_text_dropdown__text {
    flex: 1 1 40%;
  }
}
@media (min-width: 1400px) {
  .img_text_dropdown__text {
    flex: 1 1 35%;
  }
}
.img_text_dropdown__products {
  flex: 0 0 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .img_text_dropdown__products {
    order: 3;
  }
}
.img_text_dropdown__products:not(.active) {
  max-height: 0 !important;
}
.img_text_dropdown__products__product {
  border-top: 1px solid rgb(25, 36, 59);
  border-top: 1px solid rgba(var(--color-primary), 1);
  display: flex;
  padding: 1.5rem 0 0;
  justify-content: space-between;
  align-items: center;
}
.img_text_dropdown__products__product:first-of-type {
  margin-top: 3rem;
}
.img_text_dropdown__products__product:last-of-type {
  border-bottom: 1px solid rgb(25, 36, 59);
  border-bottom: 1px solid rgba(var(--color-primary), 1);
}
@media (min-width: 768px) {
  .img_text_dropdown__products__product {
    min-height: 8rem;
    padding: 1.5rem 5rem;
  }
}
.img_text_dropdown__products__product__title {
  font-weight: 700;
  margin-right: 3rem;
}
.img_text_dropdown__products__product__title--large {
  font-size: 2rem;
}
@media (min-width: 768px) {
  .img_text_dropdown__products__product__title--large {
    font-size: 2.5rem;
  }
}
@media (max-width: 767.98px) {
  .img_text_dropdown__products__product__title {
    flex: 1 1 auto;
  }
}
.img_text_dropdown__products__product .datasheet {
  display: none;
}
@media (min-width: 768px) {
  .img_text_dropdown__products__product .datasheet {
    display: block;
  }
}
@media (max-width: 767.98px) {
  .img_text_dropdown__products__product .img_text_dropdown__products__product__title {
    margin-bottom: 1.5rem;
  }
}
.img_text_dropdown__products__product .btn {
  min-width: 11rem;
}
@media (min-width: 768px) {
  .img_text_dropdown__products__product .btn {
    margin: 0 0 0 2rem;
  }
}
.img_text_dropdown__products__product .btn__wrapper {
  width: unset;
}
.img_text_dropdown__products__product__intro {
  display: block;
  opacity: 0;
  white-space: nowrap;
  flex: 1 1 auto;
}
@media (max-width: 767.98px) {
  .img_text_dropdown__products__product__intro {
    margin-bottom: 1.5rem;
    flex: 1 1 100%;
    display: none;
  }
}
@media (min-width: 768px) {
  .img_text_dropdown__products__product__intro {
    text-align: center;
  }
}
.img_text_dropdown__products__spacer {
  width: 100%;
  height: 8rem;
  border-bottom: none !important;
}

.link {
  text-decoration: none;
  font-weight: 700;
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
  padding-right: 4rem;
  max-width: 100%;
  width: unset;
  position: relative;
}
.link__wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
.link svg {
  height: 1rem;
  width: 0.65rem;
  transition: all 400ms cubic-bezier(0.47, 1.64, 0.41, 0.8);
  position: relative;
  display: inline;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 767.98px) {
  .link svg {
    margin-left: 1.5rem;
  }
}
@media (min-width: 768px) {
  .link svg {
    margin-left: 3rem;
  }
}
.link svg path {
  fill: rgb(209, 39, 47);
  fill: rgba(var(--color-secondary), 1);
}
@media (max-width: 767.98px) {
  .link:hover svg {
    margin-left: 0.5rem;
  }
}
@media (min-width: 768px) {
  .link:hover svg {
    margin-left: 1rem;
    margin-right: 2rem;
  }
}

@media (min-width: 768px) {
  .menu {
    display: flex;
    align-items: center;
  }
}
.menu__item {
  white-space: nowrap;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .menu__item {
    padding-bottom: 0rem;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.menu__item__link {
  font-weight: 700;
  display: block;
  padding-bottom: 1rem;
}
@media (min-width: 768px) {
  .menu__item__link {
    padding-bottom: 2rem;
    font-weight: 400;
    text-decoration: none;
    color: rgb(25, 36, 59);
    color: rgba(var(--color-primary), 1);
    padding-bottom: 1.5rem;
    padding-top: 1.5rem;
    position: relative;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: max-content;
  }
}
@media (min-width: 1200px) {
  .menu__item__link {
    font-size: 1.4rem;
  }
}
.menu__item.active .menu__item__link, .menu__item.activee .menu__item__link {
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
  font-weight: 700;
}
@media (min-width: 768px) {
  .menu__item:hover .menu__item__link {
    color: rgb(209, 39, 47);
    color: rgba(var(--color-secondary), 1);
  }
  .menu__item.active .menu__item__link::after, .menu__item.activee .menu__item__link::after {
    background-color: rgb(209, 39, 47);
    background-color: rgba(var(--color-secondary), 1);
  }
}
@media (min-width: 768px) {
  .menu__item .menu__item__link {
    margin: 0 1rem;
  }
}
@media (min-width: 1400px) {
  .menu__item .menu__item__link {
    margin: 0 2rem;
  }
}
.menu__item:first-of-type {
  margin-left: 0;
}
.menu__item--dropdown svg {
  display: none;
}
@media (min-width: 768px) {
  .menu__item--dropdown svg {
    display: inline-block;
    height: 1rem;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin-left: 1rem;
  }
}
@media (max-width: 767.98px) {
  .menu__item--dropdown__item__link {
    padding-bottom: 1rem;
    display: block;
    padding-left: 2rem;
  }
}
@media (min-width: 768px) {
  .menu__item--dropdown {
    position: relative;
  }
  .menu__item--dropdown__wrapper {
    position: absolute;
    transform: translateY(100%);
    max-height: 0;
    overflow: hidden;
    bottom: 0;
    left: 0;
    min-width: 100%;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .menu__item--dropdown ul {
    background: #fff;
    display: flex;
    flex-direction: column !important;
    width: 100%;
  }
  .menu__item--dropdown ul li {
    width: 100%;
  }
  .menu__item--dropdown ul li a {
    padding: 1rem 2rem;
    width: 100%;
    display: block;
  }
  .menu__item--dropdown:not(.activee) .menu__item--dropdown__wrapper {
    max-height: 0 !important;
  }
  .menu__item--dropdown.activee svg {
    transform: rotate(180deg);
  }
}

.search {
  padding: 0 !important;
  width: 30rem !important;
  border-radius: 0.8rem !important;
}
.search svg {
  position: absolute;
  left: 1.5rem;
  top: 50%;
  transform: translateY(-50%);
}
.search svg path {
  fill: rgb(25, 36, 59);
  fill: rgba(var(--color-primary), 1);
}
.search svg + input {
  padding-left: 4.5rem;
}
.search input {
  border-radius: 0.8rem !important;
  background-color: transparent !important;
}
.search input[type=search] {
  -webkit-appearance: none;
}

@media (max-width: 767.98px) {
  .sidebar {
    display: none;
  }
}
.sidebar__button {
  background-color: rgb(250, 250, 250);
  background-color: rgba(var(--color-lightgrey), 1);
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  box-shadow: 0px 0 4px rgba(0, 0, 0, 0.1411764706);
  margin-right: -3.5rem;
}
.sidebar__button svg {
  pointer-events: none;
}
.sidebar svg {
  transform: rotate(-90deg);
  width: 1rem;
  height: 1rem;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.sidebar svg path {
  fill: rgb(25, 36, 59);
  fill: rgba(var(--color-primary), 1);
}
.sidebar__part {
  margin-bottom: 7rem;
}
.sidebar__part__title {
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 3rem;
}
.sidebar__part__list__item__link {
  display: block;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar__part__list__item__link.active {
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
  font-weight: bold;
}
.sidebar__part__list__item__link:not(.active) + .sidebar__part__sublist {
  height: 0 !important;
}
.sidebar__part__list__item ul {
  overflow: hidden;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.sidebar__part__list__item.active .sidebar__part__list__item__link {
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
  font-weight: bold;
}
.sidebar__part__sublist, .sidebar__part__subsublist {
  padding-left: 2rem;
  -webkit-transition: height 0.2s ease-in-out;
  -moz-transition: height 0.2s ease-in-out;
  -o-transition: height 0.2s ease-in-out;
  -ms-transition: height 0.2s ease-in-out;
  transition: height 0.2s ease-in-out;
}
.sidebar__part__sublist__item__link, .sidebar__part__subsublist__item__link {
  padding-right: 40px;
}
.sidebar__part__sublist__item__link.active, .sidebar__part__subsublist__item__link.active {
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
  font-weight: bold;
}
.sidebar__part__sublist__item:not(.active) + .sidebar__part__subsublist, .sidebar__part__subsublist__item:not(.active) + .sidebar__part__subsublist {
  height: 0 !important;
}
.sidebar__part__sublist__item__link, .sidebar__part__subsublist__item__link {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
}
.sidebar__part__sublist__item__link.active, .sidebar__part__subsublist__item__link.active {
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
}

.breadcrumb__wrapper {
  z-index: 0;
  position: relative;
  position: absolute;
  top: 3rem;
  width: 100%;
}
@media (max-width: 767.98px) {
  .breadcrumb__wrapper {
    display: none;
  }
}
.breadcrumb__wrapper .plate--column {
  min-height: 0;
}

.plate--column section {
  margin: 0 -2.5rem;
}
@media (min-width: 768px) {
  .plate--column section {
    margin: 0 -3.5rem;
  }
}

a.sidebar__part__list__item__link.active svg,
a.sidebar__part__sublist__item__link.active svg {
  transform: rotate(0);
}
a.sidebar__part__list__item__link.active svg path,
a.sidebar__part__sublist__item__link.active svg path {
  fill: rgb(209, 39, 47);
  fill: rgba(var(--color-secondary), 1);
}

@media (max-width: 1199.98px) {
  .menu_item__submenu {
    display: none;
  }
}
.menu_item__submenu .card {
  -webkit-transition: box-shadow 0.1s ease-in-out;
  -moz-transition: box-shadow 0.1s ease-in-out;
  -o-transition: box-shadow 0.1s ease-in-out;
  -ms-transition: box-shadow 0.1s ease-in-out;
  transition: box-shadow 0.1s ease-in-out;
}
.menu_item__submenu .card:hover {
  box-shadow: 0 6px 15px 0px rgba(0, 0, 0, 0.1254901961);
}
.menu_item__submenu .card .image {
  padding-bottom: 140% !important;
}
.menu_item__submenu .title--column {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.menu_item__submenu .title--column .link {
  margin-bottom: 2.5rem;
}
.menu_item__submenu__wrapper {
  pointer-events: none;
}
@media (max-width: 1199.98px) {
  .menu_item__submenu__wrapper {
    display: none;
  }
}
@media (min-width: 1200px) {
  .menu_item__submenu {
    overflow: hidden;
    opacity: 0;
  }
  .menu_item__submenu .card {
    margin-bottom: 1.6rem;
  }
  .menu_item__submenu .card .image {
    padding-bottom: 140%;
    width: 100%;
    position: relative;
  }
  .menu_item__submenu .card .image img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
  .menu_item__submenu:not(.active) {
    max-height: 0 !important;
  }
  .menu_item__submenu__wrapper {
    max-height: calc(100vh - 7rem);
    overflow: scroll;
    flex-direction: column;
    border-top: 2px solid rgba(0, 0, 0, 0.16);
    z-index: 10;
    position: absolute;
    width: 100vw;
    bottom: 0;
    transform: translateY(100%);
    background-color: rgb(250, 250, 250);
    background-color: rgba(var(--color-lightgrey), 1);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
  }
  .menu_item__submenu__wrapper .plate--column {
    min-height: 0;
  }
  .menu_item__submenu__wrapper .plate--container--submenu {
    padding-top: 4.5rem;
    padding-bottom: 4.5rem;
  }
  .menu_item__submenu__wrapper.active {
    opacity: 1;
    pointer-events: all;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.1607843137);
  }
  .menu_item__submenu__wrapper.active--search {
    opacity: 1;
    pointer-events: all;
    box-shadow: 0 30px 99px rgba(0, 0, 0, 0.1607843137);
  }
  .menu_item__submenu__wrapper:not(.active--search) .menu_item__search__wrapper {
    max-height: 0 !important;
    overflow: hidden;
  }
  .menu_item__submenu .plate--row {
    padding-bottom: 4rem;
  }
  .menu_item__submenu.active {
    opacity: 1;
  }
  .menu_item__submenu.active--search {
    opacity: 1;
  }
  .menu_item__submenu__title {
    font-size: 2.4rem;
    line-height: 3.5rem;
    font-weight: 700;
    color: rgb(25, 36, 59);
    color: rgba(var(--color-primary), 1);
    margin-bottom: 2rem;
    position: relative;
    display: block;
    width: max-content;
    padding-right: 3rem;
  }
  .menu_item__submenu__title:not(.noline) {
    padding-bottom: 1.5rem;
  }
  .menu_item__submenu__title:not(.noline)::after, .menu_item__submenu__title:not(.noline)::before {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: rgb(209, 39, 47);
    background-color: rgba(var(--color-secondary), 1);
    height: 0.4rem;
  }
  .menu_item__submenu__title:not(.noline)::before {
    left: 0;
    width: 2rem;
  }
  .menu_item__submenu__title:not(.noline)::after {
    left: 3.5rem;
    width: 6.5rem;
  }
  .menu_item__submenu__title:not(.noline).text-center::after, .menu_item__submenu__title:not(.noline).text-center::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .menu_item__submenu__title:not(.noline).text-center::after {
    transform: translateX(calc(-50% - 1.75rem));
  }
  .menu_item__submenu__title:not(.noline).text-center::before {
    transform: translateX(calc(-50% + 3.75rem));
  }
  .menu_item__submenu__title:not(.noline).text-right::before {
    right: 0;
    width: 2rem;
  }
  .menu_item__submenu__title:not(.noline).text-right::after {
    right: 3.5rem;
    width: 6.5rem;
  }
  .menu_item__submenu__title:not(.noline).line-reversed::before {
    left: 8rem;
  }
  .menu_item__submenu__title:not(.noline).line-reversed::after {
    left: 0;
  }
  .menu_item__submenu__title:not(.noline).line-reversed.text-center::after, .menu_item__submenu__title:not(.noline).line-reversed.text-center::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .menu_item__submenu__title:not(.noline).line-reversed.text-center::after {
    transform: translateX(calc(-50% + 3.5rem));
  }
  .menu_item__submenu__title:not(.noline).line-reversed.text-center::before {
    transform: translateX(calc(-50% - 3.5rem));
  }
  .menu_item__submenu__title:not(.noline).line-reversed.text-right::before {
    right: 8rem;
    width: 2rem;
  }
  .menu_item__submenu__title:not(.noline).line-reversed.text-right::after {
    right: 0;
    width: 6.5rem;
  }
  .menu_item__submenu__title.line-top {
    padding-top: 1.5rem;
  }
  .menu_item__submenu__title.line-top::before {
    content: "";
    position: absolute;
    top: 0;
    background-color: rgb(209, 39, 47);
    background-color: rgba(var(--color-secondary), 1);
    height: 0.4rem;
  }
  .menu_item__submenu__title.line-top::before {
    left: 0;
    width: 6rem !important;
  }
  .menu_item__submenu__title.line-top.text-center::before {
    transform: translateX(-50%);
  }
}
@media (min-width: 1200px) and (min-width: 768px) {
  .menu_item__submenu__title {
    font-size: 3.6rem;
    line-height: 5rem;
  }
}
@media (min-width: 1200px) {
  .menu_item__submenu__title::after, .menu_item__submenu__title::before {
    content: "";
    position: absolute;
    color: rgb(209, 39, 47);
    color: rgba(var(--color-secondary), 1);
    height: 0.4rem;
    border-radius: 0.2rem;
    top: 50%;
  }
  .menu_item__submenu__title::before {
    right: 0;
    width: 10vw;
    transform: translateY(-50%) translateX(100%);
  }
  .menu_item__submenu__title::after {
    right: 0;
    transform: translateY(-50%) translateX(calc(100% + 12.5vw));
    width: 20vw;
  }
}
.menu_item__search__wrapper {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  height: 0;
  background-color: white;
}
.menu_item__search__wrapper::-webkit-scrollbar {
  display: none;
}
.menu_item__search__result__item {
  padding: 2rem 0 3rem;
  border-bottom: 1px solid rgb(25, 36, 59);
  border-bottom: 1px solid rgba(var(--color-primary), 1);
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
  text-decoration: none;
  display: block;
}
.menu_item__search__result__item__title {
  font-size: 1.8rem;
  font-weight: 700;
  display: block;
  margin-bottom: 1rem;
}
.menu_item__search__result__item:last-of-type {
  margin-bottom: 4.5rem;
}

.t {
  -webkit-transition: all 0.75s ease-in-out;
  -moz-transition: all 0.75s ease-in-out;
  -o-transition: all 0.75s ease-in-out;
  -ms-transition: all 0.75s ease-in-out;
  transition: all 0.75s ease-in-out;
  opacity: 1;
  transform: translateY(0) translateX(0);
}
.t-fade {
  opacity: 0.001;
}
.t-slide {
  transform: translateY(2rem);
}

.button_group {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.button_group .btn {
  margin: 0.5rem;
}
@media (min-width: 768px) {
  .button_group .btn {
    margin: 0.8rem;
  }
}
@media (max-width: 767.98px) {
  .button_group .btn {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .button_group .btn__wrapper {
    width: unset !important;
  }
}

:active,
:focus {
  outline: none;
}

.btn {
  min-width: 17rem;
  height: 5rem;
  margin-bottom: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: rgb(39, 223, 112);
  background-color: rgba(var(--color-tertiary), 1);
  border-radius: 0.8rem;
  color: white;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn__wrapper {
  display: flex;
  width: 100%;
}
.btn__wrapper--center {
  justify-content: center;
}
.btn__wrapper--right {
  justify-content: flex-end;
}
.btn:hover {
  color: white;
}
.btn:hover.btn--hover {
  background-color: white;
  color: rgb(39, 223, 112);
  color: rgba(var(--color-tertiary), 1);
}
.btn:hover .btn__text__basic {
  transform: translateX(-101%);
}
.btn:hover .btn__text__basic:not(.btn__text--main) {
  transform: translateY(-50%) translateX(-101%);
}
.btn:hover .btn__text__hover {
  transform: translateX(0);
}
.btn:hover .btn__text__hover span {
  transform: scale(1);
}
.btn:hover .btn__text__hover:not(.btn__text--main) {
  transform: translateY(-50%) translateX(0);
}
.btn--white {
  background-color: white;
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
}
.btn--white:hover {
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
}
.btn--primary {
  background-color: rgb(25, 36, 59);
  background-color: rgba(var(--color-primary), 1);
  color: white;
}
.btn--primary:hover {
  color: white !important;
  background-color: rgb(209, 39, 47) !important;
  background-color: rgba(var(--color-secondary), 1) !important;
}
.btn--secondary {
  background-color: rgb(209, 39, 47);
  background-color: rgba(var(--color-secondary), 1);
  color: white;
}
.btn--secondary:hover {
  color: white !important;
  background-color: rgb(25, 36, 59) !important;
  background-color: rgba(var(--color-primary), 1) !important;
}
.btn--trans--primary {
  background-color: transparent;
  border: 2px solid rgb(25, 36, 59);
  border: 2px solid rgba(var(--color-primary), 1);
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
}
.btn--trans--primary:hover {
  background-color: rgb(25, 36, 59) !important;
  background-color: rgba(var(--color-primary), 1) !important;
  color: white !important;
}
.btn--trans--primary .btn__text__hover {
  background-color: rgb(25, 36, 59) !important;
  background-color: rgba(var(--color-primary), 1) !important;
  color: white;
}
.btn--trans--secondary {
  background-color: transparent;
  border: 2px solid rgb(209, 39, 47);
  border: 2px solid rgba(var(--color-secondary), 1);
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
}
.btn--trans--secondary:hover {
  background-color: rgb(209, 39, 47) !important;
  background-color: rgba(var(--color-secondary), 1) !important;
  color: white !important;
}
.btn--trans--secondary .btn__text__hover {
  background-color: rgb(209, 39, 47) !important;
  background-color: rgba(var(--color-secondary), 1) !important;
  color: white !important;
}
.btn__text {
  padding: 0 1rem;
  text-align: center;
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.btn__text:not(.btn__text--main) {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.btn__text__hover {
  transform: translateX(100%);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
}
.btn__text__hover:not(.btn__text--main) {
  transform: translateY(-50%) translateX(100%);
}
.btn__text__hover span {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: scale(0);
}

.contact_form {
  font-size: 1.4rem;
}
.contact_form__title {
  font-weight: 700;
  margin-bottom: 3rem;
  font-size: 1.8rem;
  display: block;
}
.contact_form .explanation {
  z-index: 0 !important;
}
.contact_form__wrapper {
  display: flex;
}
.contact_form__wrapper--overlap {
  margin-top: -9rem;
}
@media (min-width: 768px) {
  .contact_form__wrapper--overlap {
    margin-top: -18rem;
  }
}
@media (max-width: 767.98px) {
  .contact_form__wrapper {
    flex-direction: column-reverse;
  }
}
.contact_form__wrapper__contact {
  flex: 1 1 100%;
  background-color: rgb(25, 36, 59);
  background-color: rgba(var(--color-primary), 1);
  color: white;
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .contact_form__wrapper__contact {
    flex: 0 0 33%;
  }
}
.contact_form__wrapper__contact__item {
  display: flex;
  position: relative;
  padding-left: 4rem;
  margin-bottom: 4rem;
}
.contact_form__wrapper__contact__item p {
  margin-bottom: 0 !important;
}
.contact_form__wrapper__contact__item svg {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.contact_form__wrapper.block {
  padding: 3rem;
}
.contact_form__wrapper.block .contact_form__wrapper__contact {
  border-radius: 0.8rem;
  margin: -3rem -3rem 3rem;
  padding: 3rem;
}
@media (min-width: 768px) {
  .contact_form__wrapper.block {
    padding: 7rem;
  }
  .contact_form__wrapper.block .contact_form__wrapper__contact {
    padding: 7rem;
    margin: -7rem -7rem -7rem 7rem;
  }
}
.contact_form__column, .contact_form__row {
  display: flex;
}
.contact_form__column {
  flex-wrap: wrap;
  flex: 1 1 10%;
}
.contact_form__label {
  font-weight: 700;
  margin-bottom: 1rem;
}
.contact_form__btn {
  width: 100%;
  background: none;
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
  border: 2px solid rgb(209, 39, 47);
  border: 2px solid rgba(var(--color-secondary), 1);
  margin-bottom: 0 !important;
}
.contact_form__btn:hover {
  color: white;
  background-color: rgb(209, 39, 47);
  background-color: rgba(var(--color-secondary), 1);
}
.contact_form .subtext {
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  width: 70%;
  margin: 3rem auto;
  display: block;
}
.contact_form .button {
  display: flex;
  justify-content: flex-end;
}
.contact_form__row {
  margin-right: -1.25rem;
  margin-left: -1.25rem;
  flex-direction: column;
}
@media (min-width: 992px) {
  .contact_form__row {
    flex-direction: row;
    margin-right: -1.75rem;
    margin-left: -1.75rem;
  }
}
.contact_form__row .contact_form__column__column {
  flex: 1 1 10%;
}
.contact_form__row .contact_form__column__column .contact_form__field {
  width: 100%;
}
.contact_form__row .contact_form__column .contact_form__field {
  padding-right: 1.25rem;
  padding-left: 1.25rem;
}
@media (min-width: 992px) {
  .contact_form__row .contact_form__column .contact_form__field {
    padding-right: 1.75rem;
    padding-left: 1.75rem;
  }
}
.contact_form input[type=date] {
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  min-height: 1.2em;
  width: 100%;
}
.contact_form__field {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.6rem;
  position: relative;
}
.contact_form__field:nth-child(1) {
  z-index: 29;
}
.contact_form__field:nth-child(2) {
  z-index: 28;
}
.contact_form__field:nth-child(3) {
  z-index: 27;
}
.contact_form__field:nth-child(4) {
  z-index: 26;
}
.contact_form__field:nth-child(5) {
  z-index: 25;
}
.contact_form__field:nth-child(6) {
  z-index: 24;
}
.contact_form__field:nth-child(7) {
  z-index: 23;
}
.contact_form__field:nth-child(8) {
  z-index: 22;
}
.contact_form__field:nth-child(9) {
  z-index: 21;
}
.contact_form__field:nth-child(10) {
  z-index: 20;
}
.contact_form__field:nth-child(11) {
  z-index: 19;
}
.contact_form__field:nth-child(12) {
  z-index: 18;
}
.contact_form__field:nth-child(13) {
  z-index: 17;
}
.contact_form__field:nth-child(14) {
  z-index: 16;
}
.contact_form__field:nth-child(15) {
  z-index: 15;
}
.contact_form__field:nth-child(16) {
  z-index: 14;
}
.contact_form__field:nth-child(17) {
  z-index: 13;
}
.contact_form__field:nth-child(18) {
  z-index: 12;
}
.contact_form__field:nth-child(19) {
  z-index: 11;
}
.contact_form__field:nth-child(20) {
  z-index: 10;
}
.contact_form__field:nth-child(21) {
  z-index: 9;
}
.contact_form__field:nth-child(22) {
  z-index: 8;
}
.contact_form__field:nth-child(23) {
  z-index: 7;
}
.contact_form__field:nth-child(24) {
  z-index: 6;
}
.contact_form__field:nth-child(25) {
  z-index: 5;
}
.contact_form__field:nth-child(26) {
  z-index: 4;
}
.contact_form__field:nth-child(27) {
  z-index: 3;
}
.contact_form__field:nth-child(28) {
  z-index: 2;
}
.contact_form__field:nth-child(29) {
  z-index: 1;
}
.contact_form__field:nth-child(30) {
  z-index: 0;
}
.contact_form__field .file__overlay,
.contact_form__field .selector .selector__input,
.contact_form__field input,
.contact_form__field textarea {
  background-color: white;
  padding: 1.5rem 2rem;
  border-radius: 0.8rem;
  border: 2px solid rgb(25, 36, 59);
  border: 2px solid rgba(var(--color-primary), 1);
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
}
.contact_form__field .file__overlay::placeholder,
.contact_form__field .selector .selector__input::placeholder,
.contact_form__field input::placeholder,
.contact_form__field textarea::placeholder {
  opacity: 1;
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
}
.contact_form__field .file__overlay:focus::placeholder,
.contact_form__field .selector .selector__input:focus::placeholder,
.contact_form__field input:focus::placeholder,
.contact_form__field textarea:focus::placeholder {
  opacity: 0;
}
.contact_form__field textarea {
  resize: none;
  min-height: 9.4rem;
}
.contact_form__field--white .file__drop,
.contact_form__field--white .selector,
.contact_form__field--white input,
.contact_form__field--white textarea {
  border: 2px solid white;
  color: white;
}
.contact_form__field--white .file__drop::placeholder,
.contact_form__field--white .selector::placeholder,
.contact_form__field--white input::placeholder,
.contact_form__field--white textarea::placeholder {
  color: white;
}
.contact_form__field--white svg path {
  fill: white;
}
.contact_form .error {
  border: 1px solid #dc3545;
}
.contact_form select.error + div {
  border: 1px solid #dc3545;
}
.contact_form .radio_check__result.error ~ .contact_form__field__radio_check label {
  color: #dc3545;
}
.contact_form .hide {
  display: none;
}

.notification {
  position: relative;
}
.notification .message {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}
.notification.success .message__success {
  opacity: 1;
}
.notification.error .message__error {
  opacity: 1;
}

.grecaptcha-badge {
  display: none;
}

.selector {
  cursor: pointer;
  position: relative;
  z-index: 5;
}
.selector__wrapper select {
  display: none;
}
.selector__placeholder {
  pointer-events: none;
}
.selector__input {
  background: lightgrey;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.selector__input svg {
  margin-left: 2rem;
  height: 1rem;
  pointer-events: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.selector__input span {
  pointer-events: none;
}
.selector__value {
  display: none;
}
.selector__list {
  padding-top: 0.8rem;
  bottom: 0.8rem;
  z-index: -1;
  position: absolute;
  width: 100%;
  transform: translateY(100%);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  overflow: hidden;
  border-radius: 0 0 0.8rem 0.8rem;
  background: rgb(25, 36, 59);
  background: rgba(var(--color-primary), 1);
}
.selector__list__item {
  padding: 1rem;
  color: white;
}
.selector--open {
  z-index: 11;
}
.selector--open .selector__list {
  max-height: 200px;
}
.selector--open svg {
  transform: rotate(180deg);
}
.selector:not(.selector--open) .selector__list {
  max-height: 0 !important;
}
.selector--closing {
  z-index: 11;
}
.selector--selected .selector__value {
  display: block;
}
.selector--selected .selector__placeholder {
  display: none;
}

.file__button {
  background-color: rgb(25, 36, 59);
  background-color: rgba(var(--color-primary), 1);
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  border: none;
}
.file__drop {
  position: relative;
  height: 100%;
  min-height: 9.4rem;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.file__overlay {
  z-index: 10;
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
}
.file__overlay .filename {
  flex: 1 1 100%;
  text-align: center;
  margin-bottom: 1rem;
}
.file__overlay .file__remove {
  pointer-events: all;
}
.file input {
  opacity: 0;
  cursor: pointer;
  width: 100%;
  height: 100%;
}
.file .drag_over .file__overlay {
  background-color: rgb(250, 250, 250);
  background-color: rgba(var(--color-lightgrey), 1);
}
.file .file__remove {
  display: none;
}
.file .has_file .file__overlay {
  background: rgb(25, 36, 59);
  background: rgba(var(--color-primary), 1);
  color: white;
}
.file .has_file .file__add {
  display: none;
}
.file .has_file .file__remove {
  display: block;
}

.contact_form__field.range {
  width: 100%;
}

.filter__input__range {
  padding-bottom: 2rem;
  position: relative;
  width: 100%;
}
.filter__input__range__bar {
  height: 0.2rem;
  position: relative;
  background-color: rgb(211, 211, 211);
  margin: 7px 0.4rem 0;
}
.filter__input__range__bar::after, .filter__input__range__bar::before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  width: 0.8rem;
  height: 0.8rem;
  background-color: rgb(211, 211, 211);
  border-radius: 50%;
}
.filter__input__range__bar::before {
  left: 0;
  transform: translateY(-50%) translateX(-50%);
}
.filter__input__range__bar::after {
  right: 0;
  transform: translateY(-50%) translateX(50%);
}
.filter__input__range__bar__max, .filter__input__range__bar__min {
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: white;
  border: 3px solid rgb(25, 36, 59);
  border: 3px solid rgba(var(--color-primary), 1);
  z-index: 2;
}
.filter__input__range__bar__range {
  height: 0.4rem;
  background: rgb(25, 36, 59);
  background: rgba(var(--color-primary), 1);
  transform: translateY(-50%);
}
.filter__input__range__bar__values {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(120%);
}
.filter__input__range__bar__max, .filter__input__range__bar__min, .filter__input__range__bar__range {
  position: absolute;
  top: 50%;
}
.filter__input__range__bar__min {
  left: 0;
}
.filter__input__range__bar__max {
  left: 100%;
}
.filter__input__range__values {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter__input__range__selector input {
  pointer-events: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
  transform: translateY(-50%);
  padding: 0;
}
.filter__input__range__selector {
  position: absolute;
  z-index: 10;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  height: 20px;
}
.filter__input__range__selector--active {
  pointer-events: all !important;
}

[hidden] {
  display: block !important;
}

.solutions-products__item--disabled {
  display: none;
}
.solutions-products__solutions {
  margin-bottom: 1.6rem;
}
.solutions-products--disabled {
  pointer-events: none;
  opacity: 0.4;
}

.form_steps {
  display: flex;
}
.form_steps__navigation {
  flex: 0 auto;
  max-width: 40rem;
  padding: 3rem;
  background-color: rgb(239, 239, 239);
}
.form_steps__navigation button {
  display: block;
}
.form_steps__navigation button:not(:last-child) {
  margin-bottom: 1.5rem;
}
.form_steps__navigation button.active {
  background-color: red;
}
.form_steps__wrapper {
  flex: 1;
  padding: 1rem;
  background-color: rgb(239, 239, 239);
}
@media (min-width: 768px) {
  .form_steps__wrapper {
    padding: 3rem;
    margin-left: 3.5rem;
  }
}
.form_steps__item {
  margin-right: -2.5rem;
  margin-left: -2.5rem;
}
@media (min-width: 768px) {
  .form_steps__item {
    margin-right: -3.5rem;
    margin-left: -3.5rem;
  }
}
.form_steps__item:not(.active) {
  display: none;
}
.form_steps__item__nav {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
@media (min-width: 768px) {
  .form_steps__item__nav {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
}

.image img {
  max-width: 100%;
}
.image--large {
  width: calc(100% + 2 * 2.5rem);
  margin-left: -2.5rem;
}
@media (min-width: 768px) {
  .image--large {
    height: 100%;
    width: calc(100% + 2 * 3.5rem);
    margin-left: -3.5rem;
  }
  .image--large img {
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 768px) {
  .width_desktop {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .width_desktop img,
  .width_desktop picture {
    width: unset;
  }
}

@media (max-width: 767.98px) {
  .width_mobile {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .width_mobile img,
  .width_mobile picture {
    width: unset;
  }
}

@media (min-width: 768px) {
  .plate--element__image {
    flex: 1 1 auto;
  }
}
@media (min-width: 768px) {
  .plate--row .plate--column:first-of-type .image--large-col-1, .plate--row .plate--column:last-of-type .image--large-col-1 {
    width: calc((100vw - 100% / 1 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-2, .plate--row .plate--column:last-of-type .image--large-col-2 {
    width: calc((100vw - 100% / 2 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-3, .plate--row .plate--column:last-of-type .image--large-col-3 {
    width: calc((100vw - 100% / 3 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-4, .plate--row .plate--column:last-of-type .image--large-col-4 {
    width: calc((100vw - 100% / 4 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-5, .plate--row .plate--column:last-of-type .image--large-col-5 {
    width: calc((100vw - 100% / 5 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-6, .plate--row .plate--column:last-of-type .image--large-col-6 {
    width: calc((100vw - 100% / 6 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-7, .plate--row .plate--column:last-of-type .image--large-col-7 {
    width: calc((100vw - 100% / 7 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-8, .plate--row .plate--column:last-of-type .image--large-col-8 {
    width: calc((100vw - 100% / 8 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-9, .plate--row .plate--column:last-of-type .image--large-col-9 {
    width: calc((100vw - 100% / 9 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-10, .plate--row .plate--column:last-of-type .image--large-col-10 {
    width: calc((100vw - 100% / 10 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-11, .plate--row .plate--column:last-of-type .image--large-col-11 {
    width: calc((100vw - 100% / 11 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-12, .plate--row .plate--column:last-of-type .image--large-col-12 {
    width: calc((100vw - 100% / 12 * 12) / 2 + 100%);
  }
  .plate--row .plate--column:first-of-type .image--large-col-1 {
    margin-left: calc((100vw - 100% / 1 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-2 {
    margin-left: calc((100vw - 100% / 2 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-3 {
    margin-left: calc((100vw - 100% / 3 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-4 {
    margin-left: calc((100vw - 100% / 4 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-5 {
    margin-left: calc((100vw - 100% / 5 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-6 {
    margin-left: calc((100vw - 100% / 6 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-7 {
    margin-left: calc((100vw - 100% / 7 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-8 {
    margin-left: calc((100vw - 100% / 8 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-9 {
    margin-left: calc((100vw - 100% / 9 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-10 {
    margin-left: calc((100vw - 100% / 10 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-11 {
    margin-left: calc((100vw - 100% / 11 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:first-of-type .image--large-col-12 {
    margin-left: calc((100vw - 100% / 12 * 12) / -2 + 3.5rem);
  }
  .plate--row .plate--column:last-of-type:not(:only-child) .image--large {
    margin-left: -3.5rem;
  }
  .plate--row .plate--column:only-child .image--large {
    margin-left: calc((100vw - 100%) / -2);
    width: 100vw;
  }
  .section--padding-top-small .plate--row:first-of-type [data-plate-object-type=element_placeholder] + .plate--element__image .image--large:not(.image--single),
  .section--padding-top-small .plate--row:first-of-type .plate--element__image:first-of-type .image--large:not(.image--single) {
    height: calc(100% + 6rem);
    margin-top: -6rem;
    flex: 1 1 50%;
  }
  .section--padding-top-small .plate--row:only-child .image--single {
    height: calc(100% + 6rem * 2);
    margin-top: -6rem;
    flex: 1 1 auto;
  }
  .section--padding-top-medium .plate--row:first-of-type [data-plate-object-type=element_placeholder] + .plate--element__image .image--large:not(.image--single),
  .section--padding-top-medium .plate--row:first-of-type .plate--element__image:first-of-type .image--large:not(.image--single) {
    height: calc(100% + 12rem);
    margin-top: -12rem;
    flex: 1 1 50%;
  }
  .section--padding-top-medium .plate--row:only-child .image--single {
    height: calc(100% + 12rem * 2);
    margin-top: -12rem;
    flex: 1 1 auto;
  }
  .section--padding-top-large .plate--row:first-of-type [data-plate-object-type=element_placeholder] + .plate--element__image .image--large:not(.image--single),
  .section--padding-top-large .plate--row:first-of-type .plate--element__image:first-of-type .image--large:not(.image--single) {
    height: calc(100% + 24rem);
    margin-top: -24rem;
    flex: 1 1 50%;
  }
  .section--padding-top-large .plate--row:only-child .image--single {
    height: calc(100% + 24rem * 2);
    margin-top: -24rem;
    flex: 1 1 auto;
  }
  .section--padding-bottom-small .plate--row:last-of-type [data-plate-object-type=element_placeholder] + .plate--element__image .image--large:not(.image--single),
  .section--padding-bottom-small .plate--row:last-of-type .plate--element__image:last-of-type .image--large:not(.image--single) {
    height: calc(100% + 6rem);
    margin-bottom: -6rem;
    flex: 1 1 50%;
  }
  .section--padding-bottom-small .plate--row:only-child .image--single {
    height: calc(100% + 6rem * 2);
    margin-bottom: -6rem;
    flex: 1 1 auto;
  }
  .section--padding-bottom-medium .plate--row:last-of-type [data-plate-object-type=element_placeholder] + .plate--element__image .image--large:not(.image--single),
  .section--padding-bottom-medium .plate--row:last-of-type .plate--element__image:last-of-type .image--large:not(.image--single) {
    height: calc(100% + 12rem);
    margin-bottom: -12rem;
    flex: 1 1 50%;
  }
  .section--padding-bottom-medium .plate--row:only-child .image--single {
    height: calc(100% + 12rem * 2);
    margin-bottom: -12rem;
    flex: 1 1 auto;
  }
  .section--padding-bottom-large .plate--row:last-of-type [data-plate-object-type=element_placeholder] + .plate--element__image .image--large:not(.image--single),
  .section--padding-bottom-large .plate--row:last-of-type .plate--element__image:last-of-type .image--large:not(.image--single) {
    height: calc(100% + 24rem);
    margin-bottom: -24rem;
    flex: 1 1 50%;
  }
  .section--padding-bottom-large .plate--row:only-child .image--single {
    height: calc(100% + 24rem * 2);
    margin-bottom: -24rem;
    flex: 1 1 auto;
  }
}
.img_text_element {
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  padding-bottom: 70%;
  text-decoration: none;
  color: white;
  display: block;
  margin-bottom: 2.5rem;
  position: relative;
}
.img_text_element:hover {
  color: white;
}
@media (min-width: 768px) {
  .img_text_element {
    margin-bottom: 3.5rem;
    padding-bottom: 50%;
  }
}
.img_text_element--dark {
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
}
.img_text_element__image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.img_text_element__image img {
  width: 100%;
  border-radius: 0.8rem;
}
.img_text_element__text {
  border-radius: 0 0 0.8rem 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-position: bottom center;
  background-size: cover;
  padding: 2rem;
}
@media (min-width: 768px) {
  .img_text_element__text {
    border-radius: 0 0.8rem 0.8rem 0;
    height: 100%;
    left: unset;
    right: 0;
    width: 70%;
    font-size: 1.4rem;
    line-height: 2rem;
    background-position: right center;
  }
}
@media (min-width: 1400px) {
  .img_text_element__text {
    width: 50%;
  }
}
.img_text_element__text b {
  font-weight: 700;
}
.img_text_element__text h2 {
  font-size: 1.6rem;
  margin-bottom: 1rem;
}

.map {
  width: 100%;
  padding-bottom: 40%;
}

.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive {
  height: 3rem;
}
.leaflet-marker-icon.leaflet-zoom-animated.leaflet-interactive path {
  fill: rgb(25, 36, 59);
  fill: rgba(var(--color-primary), 1);
}

[src="https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png"] {
  display: none !important;
}

.paragraph.noline h2 {
  padding-bottom: 0 !important;
}
.paragraph.noline h2::after, .paragraph.noline h2::before {
  display: none !important;
}
.paragraph.line-top h2 {
  padding-bottom: 0 !important;
  padding-top: 1.5rem;
}
.paragraph.line-top h2::after {
  display: none !important;
}
.paragraph.line-top h2::before {
  content: "";
  position: absolute;
  top: 0;
  background-color: rgb(209, 39, 47);
  background-color: rgba(var(--color-secondary), 1);
  height: 0.4rem;
}
.paragraph.line-top h2::before {
  left: 0;
  width: 6rem !important;
}
.paragraph.line-top h2.text-center::before {
  transform: translateX(-50%);
}
.paragraph.line-reversed h2::before {
  left: 8rem;
}
.paragraph.line-reversed h2::after {
  left: 0;
}
.paragraph.line-reversed h2.text-center::after, .paragraph.line-reversed h2.text-center::before {
  left: 50%;
  transform: translateX(-50%);
}
.paragraph.line-reversed h2.text-center::after {
  transform: translateX(calc(-50% + 3.5rem));
}
.paragraph.line-reversed h2.text-center::before {
  transform: translateX(calc(-50% - 3.5rem));
}
.paragraph.line-reversed h2.text-right::before {
  right: 8rem;
  width: 2rem;
}
.paragraph.line-reversed h2.text-right::after {
  right: 0;
  width: 6.5rem;
}
.paragraph ul li {
  padding-left: 1.5rem;
  position: relative;
}
.paragraph ul li::before {
  content: "";
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  display: block;
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
  position: absolute;
  left: 0;
  top: 1rem;
}
.paragraph ol,
.paragraph p,
.paragraph table,
.paragraph ul {
  margin-bottom: 1.6rem;
}
.paragraph ol {
  counter-reset: my-awesome-counter;
}
.paragraph ol li {
  padding-left: 2rem;
  counter-increment: my-awesome-counter;
}
.paragraph ol li::before {
  content: counter(my-awesome-counter) ". ";
  top: 0;
  font-weight: 600;
}
.paragraph a {
  text-decoration: underline;
}
.paragraph a:hover {
  text-decoration: none;
}

.slider {
  overflow: hidden;
}
.slider .plate--row {
  margin: 0;
}
.slider .plate--row img {
  border-radius: 0.8rem;
}
@media (max-width: 767.98px) {
  .slider .plate--row img {
    margin-bottom: 3rem;
  }
}
.slider .swiper-slide {
  border-radius: 0.8rem;
  overflow: hidden;
}
.slider .swiper-wrapper {
  align-items: center;
  /* add this will all relevant prefixes */
}
@media (max-width: 767.98px) {
  .slider--large {
    margin-left: 0 !important;
    width: 100% !important;
  }
}
.slider img,
.slider picture {
  width: 100%;
}
.slider__pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem 0;
}
.slider .swiper-pagination-bullet {
  opacity: 1;
  background: none;
  background-color: none;
  width: 0.6rem;
  height: 0.6rem;
  margin: 0 0.5rem !important;
  border: 1px solid rgb(25, 36, 59);
  border: 1px solid rgba(var(--color-primary), 1);
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.slider .swiper-pagination-bullet-active {
  background-color: rgb(25, 36, 59);
  background-color: rgba(var(--color-primary), 1);
}

.plate--column:not(:first-of-type) .slider--large {
  margin-left: 0 !important;
}
.plate--column:not(:first-of-type):not(:last-of-type) .slider--large {
  width: 100% !important;
}

.video {
  padding-bottom: 56%;
  position: relative;
  width: 100%;
}
.video,
.video video {
  background-color: black;
}
.video__overlay,
.video iframe,
.video video {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.video__overlay {
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.video__overlay img,
.video__overlay picture {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  object-fit: cover;
}
.video__play {
  z-index: 1;
  position: relative;
  background: none;
  border: none;
  cursor: pointer;
  -webkit-transition: transform 0.2s ease-in-out;
  -moz-transition: transform 0.2s ease-in-out;
  -o-transition: transform 0.2s ease-in-out;
  -ms-transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
}
.video__play:hover {
  transform: scale(120%);
}
.video__play svg {
  height: 100px;
}
.video__play svg path {
  fill: rgb(209, 39, 47);
  fill: rgba(var(--color-secondary), 1);
}
.video--loaded .video__overlay {
  opacity: 0;
  pointer-events: none;
}
.video--loaded .video__play {
  transform: scale(0%);
}

.white_space {
  width: 100%;
}
.white_space--small {
  height: 1rem;
}
@media (min-width: 768px) {
  .white_space--small {
    height: 1rem;
  }
}
.white_space--medium {
  height: 2rem;
}
@media (min-width: 768px) {
  .white_space--medium {
    height: 2rem;
  }
}
.white_space--large {
  height: 4rem;
}
@media (min-width: 768px) {
  .white_space--large {
    height: 4rem;
  }
}
.white_space.hide_mobile {
  display: none;
}
@media (min-width: 768px) {
  .white_space.hide_mobile {
    display: block;
  }
}
@media (min-width: 768px) {
  .white_space.hide_desktop {
    display: none;
  }
}

.customer_section__logo_text, .customer_section__logos {
  display: flex;
  align-items: center;
}
.customer_section__logo_text__logo {
  margin-right: 2rem;
}
.customer_section__logo_text__logo img {
  height: 3rem;
}
@media (min-width: 768px) {
  .customer_section__logo_text__logo {
    margin-bottom: 1.6rem;
  }
}
@media (max-width: 767.98px) {
  .customer_section__logo_text__logo {
    flex: 0 0 40%;
  }
  .customer_section__logo_text__logo img {
    width: 100%;
  }
}
.customer_section__logo_text__text {
  flex: 1 1 auto;
}
.customer_section__logos {
  margin: 0 -1.25rem;
  justify-content: space-between;
  flex-wrap: wrap;
}
.customer_section__logos__logo {
  padding: 0 1.25rem;
  flex: 1 1 25%;
}
@media (max-width: 767.98px) {
  .customer_section__logos {
    margin: 0 -0.625rem;
  }
  .customer_section__logos__logo {
    padding: 0 0.625rem;
  }
}
@media (min-width: 768px) {
  .customer_section__logos {
    margin-bottom: 1.6rem;
  }
}

.history_section {
  overflow: hidden;
  margin-top: 7rem;
}
.history_section .plate--column {
  z-index: 10;
}
@media (max-width: 767.98px) {
  .history_section {
    padding-bottom: 10rem !important;
  }
  .history_section .plate--container {
    display: flex;
    flex-direction: column;
  }
  .history_section .plate--row:first-of-type {
    order: 10;
  }
}
.history_section.section--bg--angle--dark::before {
  height: 20rem;
}
@media (min-width: 768px) {
  .history_section.section--bg--angle--dark::before {
    top: 12rem;
  }
}
.history_section.section--bg--angle--dark::after {
  content: "";
  background-color: rgb(25, 36, 59);
  background-color: rgba(var(--color-primary), 1);
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: calc(100% - 20rem);
}
@media (min-width: 768px) {
  .history_section.section--bg--angle--dark::after {
    height: calc(100% - 20rem - 12rem);
  }
}
.history_section__title {
  padding: 3rem 0;
  width: calc((100vw - 100%) / 2 + 100%);
}
.history_section__title .year {
  display: none;
}
.history_section__title h2 {
  color: white;
  order: 1;
  margin-bottom: 0;
}
.history_section__title h2 .year_from {
  font-family: "Georgia";
  font-weight: 400;
}
@media (min-width: 768px) {
  .history_section__title h2 {
    padding-bottom: 0 !important;
  }
  .history_section__title h2 .year_from {
    margin-left: 1rem;
  }
  .history_section__title h2::after, .history_section__title h2::before {
    background-color: rgba(var(--color-primary), 1) !important;
    top: 50% !important;
    transform: translateY(-50%) translateX(calc(5rem + 6vw)) !important;
    right: 0 !important;
    left: unset !important;
    width: 1.5rem !important;
    z-index: 10;
    border-radius: 0 !important;
  }
  .history_section__title h2::before {
    transform: translateY(-50%) translateX(calc(5rem + 4vw)) !important;
  }
}
@media (max-width: 767.98px) {
  .history_section__title h2 {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .history_section__title .year {
    font-size: 2.4rem;
    line-height: 3.5rem;
    font-weight: 700;
    color: rgb(25, 36, 59);
    color: rgba(var(--color-primary), 1);
    margin-bottom: 2rem;
    position: relative;
    display: block;
    color: rgb(25, 36, 59);
    color: rgba(var(--color-primary), 1);
    order: 3;
    margin-bottom: 0;
    display: block;
  }
  .history_section__title .year:not(.noline) {
    padding-bottom: 1.5rem;
  }
  .history_section__title .year:not(.noline)::after, .history_section__title .year:not(.noline)::before {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: rgb(209, 39, 47);
    background-color: rgba(var(--color-secondary), 1);
    height: 0.4rem;
  }
  .history_section__title .year:not(.noline)::before {
    left: 0;
    width: 2rem;
  }
  .history_section__title .year:not(.noline)::after {
    left: 3.5rem;
    width: 6.5rem;
  }
  .history_section__title .year:not(.noline).text-center::after, .history_section__title .year:not(.noline).text-center::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .history_section__title .year:not(.noline).text-center::after {
    transform: translateX(calc(-50% - 1.75rem));
  }
  .history_section__title .year:not(.noline).text-center::before {
    transform: translateX(calc(-50% + 3.75rem));
  }
  .history_section__title .year:not(.noline).text-right::before {
    right: 0;
    width: 2rem;
  }
  .history_section__title .year:not(.noline).text-right::after {
    right: 3.5rem;
    width: 6.5rem;
  }
  .history_section__title .year:not(.noline).line-reversed::before {
    left: 8rem;
  }
  .history_section__title .year:not(.noline).line-reversed::after {
    left: 0;
  }
  .history_section__title .year:not(.noline).line-reversed.text-center::after, .history_section__title .year:not(.noline).line-reversed.text-center::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .history_section__title .year:not(.noline).line-reversed.text-center::after {
    transform: translateX(calc(-50% + 3.5rem));
  }
  .history_section__title .year:not(.noline).line-reversed.text-center::before {
    transform: translateX(calc(-50% - 3.5rem));
  }
  .history_section__title .year:not(.noline).line-reversed.text-right::before {
    right: 8rem;
    width: 2rem;
  }
  .history_section__title .year:not(.noline).line-reversed.text-right::after {
    right: 0;
    width: 6.5rem;
  }
  .history_section__title .year.line-top {
    padding-top: 1.5rem;
  }
  .history_section__title .year.line-top::before {
    content: "";
    position: absolute;
    top: 0;
    background-color: rgb(209, 39, 47);
    background-color: rgba(var(--color-secondary), 1);
    height: 0.4rem;
  }
  .history_section__title .year.line-top::before {
    left: 0;
    width: 6rem !important;
  }
  .history_section__title .year.line-top.text-center::before {
    transform: translateX(-50%);
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .history_section__title .year {
    font-size: 3.6rem;
    line-height: 5rem;
  }
}
@media (min-width: 768px) {
  .history_section__title .paragraph {
    display: flex;
    align-items: center;
  }
  .history_section__title .paragraph::after, .history_section__title .paragraph::before {
    content: "";
    flex: 1 1 auto;
    background-color: rgb(209, 39, 47);
    background-color: rgba(var(--color-secondary), 1);
    height: 0.4rem;
    border-radius: 0.2rem;
    z-index: 1;
    margin: 0 2rem;
  }
  .history_section__title .paragraph::before {
    order: 2;
    margin: 0 2rem 0 5rem;
  }
  .history_section__title .paragraph::after {
    order: 4;
    margin-right: 0;
    width: calc(100vw - 100% + (100% - (100vw - 100%)) / 6);
    flex: 0 0 auto;
  }
}
.history_section__image {
  width: 100%;
  padding-bottom: 60%;
  position: relative;
}
@media (min-width: 768px) {
  .history_section__image {
    padding-bottom: 26%;
  }
}
@media (max-width: 767.98px) {
  .history_section__image {
    margin-top: 7rem;
  }
}
.history_section__image__wrapper .link {
  margin-left: calc(20% + 3rem);
  margin-top: 3.5rem;
  display: block;
}
.history_section__image img {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  border-radius: 0.8rem;
}
@media (min-width: 768px) {
  .history_section__image img {
    height: 150%;
  }
}
.history_section__image::before {
  background-color: white;
  content: "";
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0.8rem;
  height: calc(100% + 11rem);
  width: 100vw;
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.1607843137);
  margin-bottom: -10rem;
  left: 20%;
}
@media (min-width: 768px) {
  .history_section__image::before {
    height: calc(150% + 25rem);
    margin-bottom: -17.5rem;
    left: 50%;
  }
}

.industries_section__industries {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -1.25rem;
  margin-top: 3rem;
}
.industries_section__industries .card {
  flex: 1 1 100%;
  flex: 0 1 calc(50% - 0.8rem);
  margin: 0 0.4rem 0.8rem;
}
.industries_section__industries .card .image {
  padding-bottom: 140%;
  width: 100%;
  position: relative;
}
@media (min-width: 768px) {
  .industries_section__industries .card .image {
    padding-bottom: 60%;
  }
}
.industries_section__industries .card .image img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
@media (min-width: 768px) {
  .industries_section__industries {
    margin: 0 -1.6rem;
  }
  .industries_section__industries .card {
    flex: 0 1 calc(50% - 0.8rem * 2);
    margin: 0 0.8rem 1.6rem;
  }
  .industries_section__industries .card__info {
    padding: 4rem 3rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .industries_section__industries .card__title {
    font-size: 1.8rem;
    font-weight: 700;
  }
}

@media (max-width: 767.98px) {
  body {
    overflow: hidden;
  }
}

@media (max-width: 767.98px) {
  .questions_section .plate--row {
    flex-direction: column;
  }
}
@media (min-width: 768px) {
  .questions_section .plate--row {
    flex-direction: row-reverse;
  }
}
@media (min-width: 768px) {
  .questions_section .plate--row {
    background: none;
    box-shadow: none;
  }
}
@media (max-width: 767.98px) {
  .questions_section .plate--row {
    margin: 0 1.25rem;
  }
  .questions_section .plate--row .questions_section__accordion {
    margin: 0 -2rem;
  }
}
@media (min-width: 768px) {
  .questions_section h2 {
    margin-bottom: 5rem;
  }
}
.questions_section__form h3 {
  text-align: center;
  white-space: pre-wrap;
  text-align: center;
  margin-bottom: 3rem;
}
@media (min-width: 768px) {
  .questions_section__form {
    margin-top: 15rem;
    z-index: 10;
  }
}
@media (max-width: 767.98px) {
  .questions_section__form {
    padding: 2rem 1.5rem;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
}
@media (max-width: 767.98px) {
  .questions_section .block--large {
    background: none;
    box-shadow: none;
  }
}
.questions_section__subtext {
  display: block;
  font-weight: 700;
  position: relative;
  margin-top: 5rem;
}
.questions_section__subtext svg {
  position: absolute;
  left: 0;
  top: 0.4rem;
  animation-name: bounce;
  animation-duration: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: alternate-reverse;
}
@keyframes bounce {
  0% {
    left: 0;
  }
  100% {
    left: -2rem;
  }
}
@media (max-width: 767.98px) {
  .questions_section__subtext {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
  .questions_section__subtext svg {
    display: none;
  }
}
@media (min-width: 768px) {
  .questions_section__subtext {
    font-size: 1.8rem;
    padding-left: 3rem;
  }
}
@media (min-width: 992px) {
  .questions_section__subtext {
    margin-left: -7rem;
  }
}

.references_section__slider .swiper-slide {
  height: unset;
  display: flex;
  align-items: center;
}
.references_section__slider .swiper-wrapper {
  height: unset;
}
.references_section .plate--column {
  justify-content: center;
}

.section {
  scroll-snap-align: center;
  scroll-snap-stop: always;
}
.section--padding-top-small {
  padding-top: 2.5rem;
}
@media (min-width: 768px) {
  .section--padding-top-small {
    padding-top: 6rem;
  }
}
.section--padding-top-medium {
  padding-top: 5rem;
}
@media (min-width: 768px) {
  .section--padding-top-medium {
    padding-top: 12rem;
  }
}
.section--padding-top-large {
  padding-top: 10rem;
}
@media (min-width: 768px) {
  .section--padding-top-large {
    padding-top: 24rem;
  }
}
.section--padding-bottom-small {
  padding-bottom: 2.5rem;
}
@media (min-width: 768px) {
  .section--padding-bottom-small {
    padding-bottom: 6rem;
  }
}
.section--padding-bottom-medium {
  padding-bottom: 5rem;
}
@media (min-width: 768px) {
  .section--padding-bottom-medium {
    padding-bottom: 12rem;
  }
}
.section--padding-bottom-large {
  padding-bottom: 10rem;
}
@media (min-width: 768px) {
  .section--padding-bottom-large {
    padding-bottom: 24rem;
  }
}
.section--center .plate--column {
  justify-content: center;
}
@media (min-width: 768px) {
  .section--balance .plate--row {
    flex-wrap: nowrap;
  }
  .section--balance .plate--column {
    min-width: unset;
    width: unset;
    max-width: unset;
    flex-grow: 1;
    flex-basis: unset;
    flex-shrink: 1;
  }
}
.section--dark {
  background-color: rgb(25, 36, 59);
  background-color: rgba(var(--color-primary), 1);
}
.section--dark,
.section--dark h2 {
  color: white;
}
.section--bg--angle {
  position: relative;
}
.section--bg--angle::before {
  content: "";
  box-shadow: 0 30px 99px rgba(0, 0, 0, 0.1607843137);
  transform-origin: center;
  transform: skewY(-2deg);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  pointer-events: none;
}
.section--bg--angle--bottom::before {
  transform-origin: bottom left;
}
.section--bg--angle--top::before {
  transform-origin: top right;
}
.section--bg--angle--dark,
.section--bg--angle--dark h2 {
  color: white;
}
.section--bg--angle--dark::before {
  background-color: rgb(25, 36, 59);
  background-color: rgba(var(--color-primary), 1);
}
.section--pattern .plate--container {
  position: relative;
}
.section--pattern .plate--container::after {
  z-index: 10;
  content: "";
  background: radial-gradient(ellipse at center, rgb(25, 36, 59) 0%, rgb(25, 36, 59) 30%, transparent 30%);
  background: radial-gradient(ellipse at center, rgba(var(--color-primary), 1) 0%, rgba(var(--color-primary), 1) 30%, transparent 30%);
  background-repeat: repeat;
  background-position: center right;
  position: absolute;
  background-size: 0.7rem 0.7rem;
  width: 4rem;
  height: 22rem;
}
@media (min-width: 768px) {
  .section--pattern .plate--container::after {
    background-size: 1rem 1rem;
    width: 6rem;
    height: 33rem;
  }
}
@media (min-width: 1400px) {
  .section--pattern .plate--container::after {
    background-size: 1.3rem 1.3rem;
    width: 8rem;
    height: 45rem;
  }
}
.section--pattern--top .plate--container::after {
  top: 0;
  transform: translateY(calc(-50% - 12rem));
}
.section--pattern--bottom .plate--container::after {
  bottom: 0;
  transform: translateY(calc(50% + 12rem));
}
.section--pattern--left .plate--container::after {
  left: 0;
}
@media (max-width: 767.98px) {
  .section--pattern--left .plate--container::after {
    left: -2rem;
  }
}
.section--pattern--right .plate--container::after {
  right: 0;
}
@media (max-width: 767.98px) {
  .section--pattern--right .plate--container::after {
    right: -2rem;
  }
}
@media (min-width: 768px) {
  .section--pattern--wide.section--pattern--left .plate--container::after {
    margin-left: calc((100vw - 100%) / -2 + 3.5rem);
  }
}
@media (min-width: 768px) {
  .section--pattern--wide.section--pattern--right .plate--container::after {
    margin-right: calc((100vw - 100%) / 2 - 3.5rem);
  }
}

.no_padding_top {
  padding-top: 0;
}

@media (max-width: 767.98px) {
  .nomobile {
    display: none;
  }
}

@media (min-width: 768px) {
  .nodesktop {
    display: none;
  }
}

.no_padding_bottom {
  padding-bottom: 0;
}

.solutions_section {
  position: relative;
  z-index: 100;
}
.solutions_section__snapper {
  z-index: -1;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.solutions_section__snapper__item {
  flex: 1 1 auto;
}
.solutions_section h2 {
  -webkit-transition: color 0.2s ease-in-out;
  -moz-transition: color 0.2s ease-in-out;
  -o-transition: color 0.2s ease-in-out;
  -ms-transition: color 0.2s ease-in-out;
  transition: color 0.2s ease-in-out;
}
.solutions_section h2:hover {
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
}
.solutions_section .sm-6 {
  overflow: hidden;
}
@media (max-width: 767.98px) {
  .solutions_section .sm-5 {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (min-width: 768px) {
  .solutions_section.section--pattern--top .plate--container::after {
    top: 0;
    transform: translateY(-25%);
  }
}
@media (min-width: 768px) {
  .solutions_section {
    height: calc(100vh - 7rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    pointer-events: none;
    position: sticky;
    position: -webkit-sticky;
    top: 7rem;
    /* required */
    transition: background-color 0.2s, height 0.2s;
  }
  .solutions_section .section {
    z-index: 100;
    display: block;
    position: relative;
    pointer-events: none;
  }
  .solutions_section .section a {
    pointer-events: all;
  }
  .solutions_section .swiper-container-vertical > .swiper-pagination-bullets {
    top: calc(50% - 3rem);
    transform: translate3d(0px, -50%, 0);
  }
  .solutions_section .swiper-wrapper {
    align-items: center;
  }
  .solutions_section__wrapper {
    width: 100vw;
  }
  .solutions_section .section {
    padding: 0;
  }
  .solutions_section--transition {
    transition: background-color 0.2s, height 0.2s, top 0.1s;
  }
  .solutions_section .section--bg--angle {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .solutions_section--fixed {
    background-color: white;
    height: calc(100vh - 7rem);
  }
}
@media (max-width: 767.98px) {
  .solutions_section {
    background-color: rgb(250, 250, 250);
    background-color: rgba(var(--color-lightgrey), 1);
  }
  .solutions_section .swiper-wrapper {
    height: 100% !important;
  }
  .solutions_section .plate--container::after {
    display: none;
  }
}
.solutions_section .plate--column {
  position: relative;
}
@media (min-width: 768px) {
  .solutions_section .plate--column {
    justify-content: center;
  }
}
@media (max-width: 767.98px) {
  .solutions_section .section--bg--angle::before {
    display: none;
  }
}
.solutions_section__main-swiper {
  position: relative;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
@media (min-width: 768px) {
  .solutions_section__main-swiper {
    z-index: 80;
  }
}
@media (max-width: 767.98px) {
  .solutions_section__main-swiper {
    display: none;
  }
}
.solutions_section__main-swiper .swiper-slide {
  opacity: 0.00001 !important;
}
@media (min-width: 768px) {
  .solutions_section__main-swiper__pagination {
    pointer-events: all !important;
    font-size: 1.2rem;
    position: absolute;
    right: 0;
    top: 50%;
    display: flex;
    transform: rotate(90deg) translateX(50%) !important;
    z-index: 20;
    transform-origin: right top;
    display: flex;
  }
  .solutions_section__main-swiper__pagination__bullet {
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    position: relative;
    width: unset;
    height: unset;
    border-radius: unset;
    background: none;
    opacity: 1;
    padding: 0 1rem;
  }
  .solutions_section__main-swiper__pagination .swiper-pagination-bullet-active {
    color: rgb(209, 39, 47);
    color: rgba(var(--color-secondary), 1);
    font-weight: 700;
  }
}
.solutions_section__image-swiper {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
@media (min-width: 768px) {
  .solutions_section__image-swiper {
    width: 100%;
  }
}
@media (max-width: 767.98px) {
  .solutions_section__image-swiper::after {
    content: "";
    position: absolute;
    background-color: rgb(250, 250, 250);
    background-color: rgba(var(--color-lightgrey), 1);
    bottom: 0;
    left: 0;
    height: 5rem;
    transform: skewY(-2deg) translateY(50%);
    width: 100%;
    z-index: 40;
  }
}
.solutions_section__image-swiper--column {
  z-index: 3;
  position: relative;
  padding-bottom: 137%;
}
@media (min-width: 768px) {
  .solutions_section__image-swiper--column {
    padding-bottom: 137%;
    margin: -5rem 0;
  }
}
.solutions_section__image-swiper .swiper-slide picture {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.solutions_section__image-swiper .swiper-slide picture img {
  width: 100%;
}
.solutions_section__image-swiper .swiper-slide, .solutions_section__text-swiper .swiper-slide {
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  -moz-transition: opacity 0.2s ease-in-out;
  -o-transition: opacity 0.2s ease-in-out;
  -ms-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}
@media (min-width: 768px) {
  .solutions_section__image-swiper .swiper-slide, .solutions_section__text-swiper .swiper-slide {
    pointer-events: none;
  }
}
.solutions_section__image-swiper .swiper-slide-active, .solutions_section__text-swiper .swiper-slide-active {
  opacity: 1;
}
.solutions_section__text-swiper {
  z-index: 10;
}
@media (max-width: 767.98px) {
  .solutions_section__text-swiper {
    background-color: rgb(250, 250, 250);
    background-color: rgba(var(--color-lightgrey), 1);
  }
}
.solutions_section__text-swiper .swiper-slide {
  display: flex;
  align-items: center;
}
.solutions_section__text-swiper--column {
  position: relative;
}
@media (min-width: 768px) {
  .solutions_section__text-swiper--column {
    justify-content: center;
  }
}
@media (min-width: 768px) {
  .solutions_section__image-swiper__pagination {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .solutions_section__image-swiper__pagination {
    z-index: 50;
    pointer-events: all;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: unset !important;
    top: unset !important;
    width: 100%;
    left: unset !important;
    padding: 0 0 1rem;
    transform: unset;
    transform: translateX(0) !important;
  }
  .solutions_section__image-swiper__pagination .swiper-pagination-bullet {
    opacity: 1;
    background: none;
    background-color: none;
    width: 0.6rem;
    height: 0.6rem;
    margin: 0 0.5rem !important;
    border: 1px solid rgb(25, 36, 59);
    border: 1px solid rgba(var(--color-primary), 1);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .solutions_section__image-swiper__pagination .swiper-pagination-bullet-active {
    background-color: rgb(25, 36, 59);
    background-color: rgba(var(--color-primary), 1);
  }
}

.footer-tray {
  position: relative;
  z-index: 1;
}

.footer {
  padding-bottom: 0;
  position: relative;
  z-index: 100;
  background-color: rgb(250, 250, 250);
  background-color: rgba(var(--color-lightgrey), 1);
}
.footer__header {
  margin-bottom: 3rem;
}
.footer__logo img {
  height: 3rem;
}
.footer .breadcrumb {
  margin-top: 0;
}
.footer::before {
  z-index: -1;
  pointer-events: none;
  background-color: rgb(250, 250, 250);
  background-color: rgba(var(--color-lightgrey), 1);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  content: "";
  transform: skewY(-2deg);
  transform-origin: left top;
}
.footer::after {
  z-index: -2;
  pointer-events: none;
  background-color: rgb(250, 250, 250);
  background-color: rgba(var(--color-lightgrey), 1);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  height: 10px;
  content: "";
  transform: skewY(-2deg);
  transform-origin: left top;
}
.footer__footer {
  text-align: center;
  margin-top: 3rem;
}

body {
  padding-top: 7rem;
}

.nav {
  position: relative;
  z-index: 9999999;
  display: flex;
  align-items: center;
  background-color: white;
  height: 7rem;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
}
@media (max-width: 1199.98px) {
  .nav .search {
    width: 100% !important;
  }
}
@media (min-width: 768px) {
  .nav .btn {
    height: 4rem;
  }
}
@media (max-width: 1199.98px) {
  .nav__menu {
    transform: translateX(-100%);
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    margin-left: calc((100vw - 100%) / 2 * -1);
    position: absolute;
    left: 0;
    top: 0;
    padding: 0 2.5rem 2.5rem;
    height: 100vh;
    width: calc(100vw - 20px - 2.5rem * 2);
    background-color: white;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    align-content: flex-start;
  }
  .nav__menu .btn,
  .nav__menu .search,
  .nav__menu .search input {
    width: 100%;
  }
  .nav__menu .btn {
    margin-bottom: 2rem;
  }
}
@media (min-width: 1200px) {
  .nav__menu {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }
}
.nav__inner {
  position: relative;
  width: 100%;
  height: 100%;
}
@media (max-width: 1199.98px) {
  .nav__inner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.nav__brand {
  position: absolute;
  width: 100%;
  display: none;
}
@media (min-width: 992px) {
  .nav__brand {
    display: flex;
    align-items: center;
  }
}
.nav__brand img {
  height: 3rem;
}
@media (min-width: 1200px) {
  .nav__brand {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .nav__brand--mobile {
    display: none;
  }
  .nav__brand--mobile img {
    height: 3rem;
  }
}
.nav__brand--mobile {
  z-index: 10;
}
.nav__brand--mobile img {
  height: 3rem;
}
.nav__brand a {
  z-index: 10;
}
@media (min-width: 768px) {
  .nav__brand a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1199.98px) {
  .nav__brand {
    order: -2;
    height: 7rem;
    flex: 0 0 auto;
  }
}
@media (min-width: 1200px) {
  .nav .btn__wrapper {
    padding: 1rem 0;
  }
  .nav .btn__wrapper .btn {
    margin-bottom: 0 !important;
  }
}
.nav__nav {
  z-index: 2;
  flex: 1 1 50%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.nav__nav .btn__wrapper {
  width: unset;
}
.nav__nav--right {
  justify-content: flex-end;
}
@media (max-width: 1199.98px) {
  .nav__nav--left {
    flex: 1 1 100%;
    margin-top: 2.5rem;
  }
}
.nav__nav ul {
  list-style: none;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1200px) {
  .nav__nav ul {
    flex-direction: row;
  }
}
@media (max-width: 1199.98px) {
  .nav__nav {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  .nav__nav {
    display: flex;
    max-height: unset;
    position: relative;
    overflow: visible;
  }
  .nav__nav .search {
    display: none;
  }
}
@media (min-width: 768px) {
  .nav .btn {
    margin: 0 0 0 1rem;
  }
}
@media (min-width: 1400px) {
  .nav .btn {
    margin: 0 0 0 2rem;
  }
}
@media (min-width: 768px) and (min-width: 768px) {
  .nav .has-lang .btn {
    margin: 0 1rem;
  }
}
@media (min-width: 768px) and (min-width: 1200px) {
  .nav .has-lang .btn {
    margin: 0 2rem;
  }
}
.nav__toggler {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  transform: translateY(-50%);
  cursor: pointer;
}
@media (min-width: 1200px) {
  .nav__toggler {
    display: none;
  }
}
.nav__toggler::after, .nav__toggler::before,
.nav__toggler span {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 3px;
  border-radius: 2px;
  background-color: black;
  width: 100%;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.nav__toggler::before {
  top: 0;
}
.nav__toggler span {
  top: 50%;
  transform: translateY(-75%);
}
.nav__toggler::after {
  top: 100%;
  transform: translateY(-100%);
  width: 50%;
}
.nav__toggler--active {
  left: calc((100vw - 100%) / 4 + 100%);
  transform: translateX(-100%) translateY(-50%);
}
.nav__toggler--active ~ .nav__menu {
  transform: translateX(0);
}

.language {
  z-index: 2;
  white-space: nowrap;
  display: flex;
}
@media (min-width: 768px) {
  .language {
    padding-left: 1rem;
  }
}
@media (min-width: 1400px) {
  .language {
    padding-left: 2rem;
  }
}
@media (max-width: 1199.98px) {
  .language {
    order: -1;
    height: 7rem;
    display: flex;
    align-items: center;
    flex: 0 0 auto;
  }
}
.language--absolute {
  position: absolute;
  right: 0;
  transform: translateX(calc(100% + 2rem));
}
.language__item {
  white-space: nowrap;
  display: inline;
}
.language__item a {
  white-space: nowrap;
  text-decoration: none;
  color: rgb(25, 36, 59);
  color: rgba(var(--color-primary), 1);
  display: inline;
}
.language__item--active a {
  font-weight: 700;
  color: rgb(209, 39, 47);
  color: rgba(var(--color-secondary), 1);
}
.language__item:not(:last-of-type)::after {
  white-space: nowrap;
  display: inline;
  content: "|";
  margin: 0 0.5rem;
}

.search__page__item {
  display: block;
  padding: 2rem 0;
  border-bottom: 1px solid rgb(25, 36, 59);
  border-bottom: 1px solid rgba(var(--color-primary), 1);
}
.search__page .breadcrumbb {
  font-size: 1.2rem;
}

.product__image {
  margin-bottom: 3rem;
}
.product__title {
  margin-bottom: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.product__title h2 {
  margin: 0;
}
.product__buttons {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 10rem;
  margin-bottom: 10rem;
  padding-top: 1rem;
  border-bottom: 1px solid rgb(25, 36, 59);
  flex-wrap: wrap;
}
@media (min-width: 768px) {
  .product__buttons {
    flex-direction: row;
  }
}
@media (max-width: 767.98px) {
  .product__buttons__downloads {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .product__buttons__downloads {
    flex: 0 0 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.product__buttons__downloads ul,
.product__buttons__downloads li,
.product__buttons__downloads p {
  padding: 0 !important;
  margin: 0 !important;
}
.product__buttons__downloads a {
  text-decoration: underline;
}
.product__buttons .btn__wrapper {
  width: unset;
}
@media (min-width: 768px) {
  .product__buttons .btn__wrapper {
    padding-left: 1.5rem;
  }
}
@media (max-width: 767.98px) {
  .product__buttons {
    flex-direction: column;
  }
  .product__buttons .btn {
    width: 100%;
  }
  .product__buttons .btn__wrapper {
    width: 100%;
  }
}

.vacancy {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 0;
}
@media (max-width: 767.98px) {
  .vacancy {
    flex-direction: column;
    align-items: flex-start;
  }
}
.vacancy:not(:last-of-type) {
  border-bottom: 1px solid rgb(25, 36, 59);
  border-bottom: 1px solid rgba(var(--color-primary), 1);
}
.vacancy h3 {
  margin-bottom: 2rem;
}
.vacancy .btn__wrapper {
  width: unset;
}
@media (min-width: 1200px) {
  .vacancy .btn__wrapper {
    padding-left: 20%;
  }
}
.vacancy .btn__wrapper .btn {
  white-space: nowrap;
}